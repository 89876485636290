import Swiper from 'swiper/bundle';

var home = new Swiper(".vswiper", {
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: true,
    keyboard: true,
    speed: 500,
    navigation: {
        nextEl: ".scroll",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

var subHome = new Swiper(".hswiper", {
    direction: "horizontal",
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: false,
    keyboard: true,
    speed: 500,
    navigation: {
        nextEl: ".hscroll",
    },
    pagination: {
        el: ".hswiper-pagination",
        clickable: true,
    },
    on : {
        slideChange : 
            function() {
                if (subHome.activeIndex == 1) {
                    document.getElementById('coaching').classList.add('active');
                } else {
                    document.getElementById('coaching').classList.remove('active');
                }
            }
        
    }
});



 window.goToCoaching = function () {
    window.location.href = "/#coaching";
} 
window.onload = function () {
    if (window.location.hash) {
        let hash = window.location.hash.substring(1);
        if (hash == "coaching") {
            home.slideTo(1);
            subHome.slideTo(1);
            document.getElementById('coaching').classList.add('active');
        } else {
            document.getElementById('coaching').classList.remove('active');
        }
    }

};
window.addEventListener("hashchange", function () {
    let hash = window.location.hash.substring(1);
    if (hash == "coaching") {
        home.slideTo(1);
        subHome.slideTo(1);
        document.getElementById('coaching').classList.add('active');
    }
});